import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Grid, Row, Col } from "react-styled-flexboxgrid";

import FilterOption from "../FilterOption";
import Dropdown from "../Dropdown";

const Content = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  border-top: 1px solid ${props => props.theme.colors.grey05};
  border-left: 1px solid ${props => props.theme.colors.grey05};

  @media (min-width: ${props => props.theme.breakpoints.tablet}px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: ${props => props.theme.breakpoints.desktop}px) {
    grid-template-columns: repeat(5, 1fr);
  }
`;

const Filterblock = ({ filters, onChange, activeFilter }) => {
  filters.sort((f1, f2) => {
    return f1.order > f2.order;
  })

  const labels = filters.map(f => {
    return f.label;
  });

  return (
    <Grid>
      <Row>
        <Col xs={false} md={12}>
          <Content>
            {filters.length > 0 &&
              filters.map((f, i) => (
                <FilterOption
                  key={`filter_${i}_${f.order}`}
                  index={i}
                  option={f.label}
                  isActive={i === activeFilter}
                  onChange={onChange}
                />
              ))}
          </Content>
        </Col>
        <Col xs={12} md={false}>
          <Dropdown
            placeholder="Select a capability"
            options={labels}
            value={activeFilter || ""}
            onChange={onChange}
          />
        </Col>
      </Row>
    </Grid>
  );
}

Filterblock.propTypes = {
  filters: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    order: PropTypes.number,
  })).isRequired,
  onChange: PropTypes.func.isRequired,
  activeFilter: PropTypes.number,
};

export default Filterblock;

import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import chevronDown from "../../images/icons/caret-down.svg";

const SelectWrapper = styled.div`
  display: flex;
  width: 100%;
  margin: 0;
  margin-bottom: 20px;
  background-color: ${(props) => props.theme.colors.white};
  border: 1px solid ${(props) => props.theme.colors.grey05};
  transition: all 100ms ease-in-out;
  box-sizing: border-box;
  position: relative;
  cursor: pointer;
  box-shadow: 0;

  &:focus-within,
  &:focus {
    outline: 0;
    box-shadow: 0;
  }
`;

const SelectInput = styled.select`
  background-color: ${(props) => props.theme.colors.white};
  font-family: "sofia-pro", "Helvetica Neue", Arial, sans-serif;
  font-weight:  ${(props) => props.theme.fontWeights.bold};
  font-size: 18px;
  padding: 24px 32px 24px 16px;
  appearance: none;
  width: 100%;
  height: 100%;
  border: 0;

  &:focus {
    outline: 0;
  }

  :-internal-autofill-previewed,
  :-internal-autofill-selected {
    background-color: #fff !important;
    background-image: none !important;
    color: ${(props) => props.theme.colors.black01} !important;
  }
`;

const Chevron = styled.div`
  position: absolute;
  right: 16px;
  pointer-events: none;
  top: 50%;
  width: 12px;
  height: 12px;

  background-image: url(${chevronDown});
  background-repeat: no-repeat;
`;

const Option = styled.option``;

const Dropdown = ({ placeholder, options, value, isActive, onChange }) => {
  const selectOption = (e) => {
    e.preventDefault();

    if (isActive) {
      onChange(null);
    } else {
      onChange(parseInt(e.target.value));
    }
  };

  return (
    <SelectWrapper>
      <SelectInput value={value} onChange={selectOption}>
        <Option value={""} disabled>
          {placeholder}
        </Option>
        {options.length > 0 &&
          options.map((option, i) => (
            <Option key={`dropdown-option-${i}`} value={i}>
              {option}
            </Option>
          ))}
      </SelectInput>
      <Chevron />
    </SelectWrapper>
  );
};

Dropdown.propTypes = {
  options: PropTypes.array,
  isActive: PropTypes.bool,
  activeFilter: PropTypes.number,
  setActiveFilter: PropTypes.func,
};

export default Dropdown;

import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Grid, Row, Col } from "react-styled-flexboxgrid";

import ButtonLink from "../ButtonLink";
import Spacer from "../Spacer";
import Heading from "../typography/Heading";

const logoOffset = 64;

const LogosContainer = styled.div`
  display: grid;
  grid-template-rows: auto;
  grid-gap: 0;
  column-gap: 25px;
  grid-template-columns: repeat(2, 1fr);

  @media (min-width: ${(props) => props.theme.breakpoints.tabletLandscape}px) {
    column-gap: 25px;
    grid-template-columns: repeat(3, 1fr);

    > a:nth-child(2) {
      margin-top: ${logoOffset}px;
    }

    > a:nth-child(4) {
      margin-top: -${logoOffset}px;
    }

    > a:nth-child(6) {
      margin-top: -${logoOffset}px;
    }
  }
`;

const SmallButtonContainer = styled.div`
  display: block;
  padding-top: 64px;
  margin-bottom: 32px;

  @media (min-width: ${(props) => props.theme.breakpoints.tablet}px) {
    display: none;
  }
`;

const ButtonContainer = styled.div`
  display: none;

  @media (min-width: ${(props) => props.theme.breakpoints.tablet}px) {
    display: flex;
    justify-content: center;
    padding-bottom: 24px;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.tabletLandscape}px) {
    justify-content: flex-end;
  }
`;

const Container = styled.section`
  position: relative;
  padding-top: 40px;
  background-color: ${(props) => props.theme.colors.blue};
  z-index: ${(props) => props.theme.zIndex.section};
  width: 100%;

  @media (min-width: ${(props) => props.theme.breakpoints.mobile}px) {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.tablet}px) {
    padding-top: 80px;
    padding-bottom: 80px;
    background-image: linear-gradient(
      60deg,
      ${(props) => props.theme.colors.blueHover} 44%,
      ${(props) => props.theme.colors.blue} 44%
    );
  }

  @media (min-width: ${(props) => props.theme.breakpoints.desktop}px) {
    padding-top: 120px;
    padding-bottom: 120px;
  }
`;

const Wrap = styled.div`
  margin: 0 auto;
  max-width: 888px;

  > div {
    width: 100%;
  }
`;

const LogoItem = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 24px 0 0;

  @media (min-width: ${(props) => props.theme.breakpoints.tablet}px) {
    margin: 32px 0 0;
    justify-content: center;
  }
`;

const LogoImageContainer = styled.div`
  align-items: center;
  display: flex;
`;

const LogoContentContainer = styled.div`
  min-height: 80px;

  h4 {
    padding: 0 10px;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.tablet}px) {
    min-height: 0;

    h4 {
      padding: 0;
    }

  }
`;

const Image = styled.img`
  max-height: 130px;
  max-width: 130px;
  margin: 0 0 32px;
  width: 100%;

  @media (min-width: ${(props) => props.theme.breakpoints.tablet}px) {
    max-height: 150px;
    max-width: 150px;
  }

`;

const LogoSubtitle = styled.div`
  margin: 5px 0 0 0;

  @media (min-width: ${(props) => props.theme.breakpoints.tablet}px) {
    margin: 5px 0 0 28px;
  }
`;

const AwardsGridBlock = ({ awards, heading, ctaText, ctaUrl }) => {
  return (
    <Container>
      <Wrap>
        <Grid>
          <Row bottom="md">
            <Col xs={12} md={8}>
              <Spacer margin={{ xs: "0 0 24px", s: "0 0 44px" }}>
                <Heading light size="l" as="h2" margin="0 0 xl">
                  {heading}
                </Heading>
              </Spacer>
            </Col>
            <Col xs={12} md={4}>
              <ButtonContainer>
                <ButtonLink
                  title={ctaText}
                  to={ctaUrl}
                  variant="tertiary"
                  margin="0 0 xxl"
                />
              </ButtonContainer>
            </Col>
          </Row>
          {awards && (
            <Row>
              <Col xs={12}>
                <LogosContainer>
                  {awards.map((item, i) => {
                    return (
                      <LogoItem key={`logo_${i}`}>
                        <LogoImageContainer>
                          <Image src={item.award_image.url}></Image>
                        </LogoImageContainer>
                        <LogoContentContainer>
                          <Heading
                            align="left"
                            size="ms"
                            as="h4"
                            slashBefore
                            light
                          >
                            {item.award_heading}
                          </Heading>
                          <LogoSubtitle>
                            <Heading align="left" size="ms" as="p" light>
                              {item.award_text}
                            </Heading>
                          </LogoSubtitle>
                        </LogoContentContainer>
                      </LogoItem>
                    );
                  })}
                </LogosContainer>
              </Col>
            </Row>
          )}
          <Row>
            <Col xs={12}>
              <SmallButtonContainer>
                <ButtonLink
                  title={ctaText}
                  to={ctaUrl}
                  variant="tertiary"
                  margin="0 0 xxl"
                />
              </SmallButtonContainer>
            </Col>
          </Row>
        </Grid>
      </Wrap>
    </Container>
  );
};

AwardsGridBlock.propTypes = {
  awards: PropTypes.array.isRequired,
  heading: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  text: PropTypes.string,
  ctaText: PropTypes.string.isRequired,
  ctaUrl: PropTypes.string.isRequired,
};

export default AwardsGridBlock;

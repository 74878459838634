import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import { Link } from "gatsby-plugin-modal-routing";
import Imgix from "react-imgix";

import externalLinkImage from "../../images/icons/external_link.svg";

import Text from "./typography/Text";

const LinkStyles = (props) => css`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid ${props.theme.colors.grey03};
  flex-wrap: nowrap;
  padding: 10px 0;
  width: 100%;
  height: 120px;
  cursor: pointer;
  transition: box-shadow 100ms ease-in-out;

  &:hover {
    background: ${props.theme.colors.white};
    box-shadow: 0 8px 10px rgba(0, 0, 0, 0.225);
  }

  &:last-child {
    border-right: 0;
  }

  @media (min-width: ${props.theme.breakpoints.mobile}px) {
    width: ${props.size === "l" ? "50%" : "100%"};
    height: ${props.size === "l" ? 260 : 210}px;
    margin-bottom: ${props.size === "l" ? 20 : 10}px;

    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
    border-bottom: 0;
    border-right: 1px solid ${props.theme.colors.grey04};

    &:nth-child(2n) {
      border-right: 0;
    }
  }

  @media (min-width: ${props.theme.breakpoints.tablet}px) {
    flex-direction: column;
    width: ${props.size === "l" ? "33%" : "100%"};
    height: ${props.size === "l" ? 320 : 220}px;
    margin-bottom: ${props.size === "l" ? 40 : 20}px;
    align-items: stretch;

    &:nth-child(even) {
      border-right: 1px solid ${props.theme.colors.grey04};
    }

    &:nth-child(3n) {
      border-right: 1px solid ${props.theme.colors.grey04};
    }

    &:nth-child(${props.size === "l" ? 3 : 4}n) {
      border-right: 0;
    }
  }
`;

const ExternalLink = styled.a`
  ${LinkStyles}
`;

const InternalLink = styled(Link)`
  ${LinkStyles}
`;

const LogoContainer = styled.span`
  display: flex;
  flex: 2 1;
  align-items: center;
  justify-content: center;
`;

const Title = styled(Text)`
  display: flex;
  padding: ${(props) => `0 0 0 ${props.theme.spacing.xl}`};
  flex: 3 1;
  justify-content: center;

  @media (min-width: ${(props) => props.theme.breakpoints.mobile}px) {
    flex: 1;
    padding: ${(props) => `0 ${props.theme.spacing.l}`};
  }

  @media (min-width: ${(props) => props.theme.breakpoints.desktop}px) {
    padding: ${(props) => `0 ${props.theme.spacing.xxl}`};
  }
`;

const Logo = styled(Imgix)`
  max-width: 90%;
  min-width: 120px;

  @media (min-width: ${(props) => props.theme.breakpoints.mobile}px) {
    max-width: 80%;
    margin-right: 0;
    min-width: 200px;
    margin-bottom: ${(props) => (props.size === "l" ? 0 : 30)}px;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.tablet}px) {
    max-width: 70%;
    max-width: 200px;
  }
`;

const ExternalLinkIcon = styled.span`
  background: url(${externalLinkImage}) center center no-repeat;
  height: 9px;
  width: 9px;
  display: inline-block;
  margin-left: 5px;
`;

const wrapWithLink = (children, size, target, isCareer, strap) => {
  if (isCareer) {
    return (
      <ExternalLink size={size} href={target} target="_blank">
        {children}
        <Title align="center">
          Visit Career Site <ExternalLinkIcon />
        </Title>
      </ExternalLink>
    );
  } else {
    return (
      <InternalLink size={size} to={target} asModal state={{ noScroll: true }}>
        {children}
        <Title align="center">{strap}</Title>
      </InternalLink>
    );
  }
};

const AgencyTile = ({ logo, target, isCareer = false, size = "m", strap }) => {
  return wrapWithLink(
    <LogoContainer>
      <Logo
        src={logo.url}
        sizes="90%"
        attributeConfig={{
          src: "data-src",
          srcSet: "data-srcset",
          sizes: "data-sizes",
        }}
        htmlAttributes={{
          alt: logo.alt,
        }}
        className="lazyload"
      />
    </LogoContainer>,
    size,
    target,
    isCareer,
    strap
  );
};

AgencyTile.propTypes = {
  size: PropTypes.oneOf(["m", "l"]),
  logo: PropTypes.shape({
    url: PropTypes.string.isRequired,
    alt: PropTypes.string,
  }).isRequired,
};

export default AgencyTile;

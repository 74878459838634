import React, { useMemo } from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import { Grid, Row, Col } from "react-styled-flexboxgrid";

import AgencyTile from "./AgencyTile";
import Spacer from "./Spacer";

const Featured = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;

  @media (min-width: ${(props) => props.theme.breakpoints.mobile}px) {
    flex-direction: row;
    flex-wrap: wrap;
  }
`;

const Content = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;

  @media (min-width: ${(props) => props.theme.breakpoints.mobile}px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 20px;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.tablet}px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (min-width: ${(props) => props.theme.breakpoints.desktop}px) {
    grid-template-columns: repeat(4, 1fr);
  }
`;

const AgenciesGrid = ({ agencies, careersOnly = false }) => {
  const featured = useMemo(() => {
    if (careersOnly) {
      return [];
    }

    return agencies.filter(({ node }) => node.agency_is_featured);
  }, [agencies, careersOnly]);

  const filtered = useMemo(() => {
    return agencies.filter(({ node }) => {
      if (careersOnly) {
        return node.agency_careers_page;
      }

      return !node.agency_is_featured;
    });
  }, [agencies, careersOnly]);

  return (
    <Spacer padding={{ xs: "0 0 50px", m: "0 0 80px", l: "0 0 100px" }}>
      <Grid>
        {!careersOnly && (
          <Row>
            <Col xs={12}>
              <Featured>
                {featured.map(({ node }) => (
                  <AgencyTile
                    logo={node.agency_logo}
                    size="l"
                    target={`/our-brands/${node._meta.uid}`}
                    key={node._meta.uid}
                    strap={node.agency_one_liner}
                  />
                ))}
              </Featured>
            </Col>
          </Row>
        )}
        <Row>
          <Col xs={12}>
            <Content>
              {filtered.map(({ node }) => (
                <AgencyTile
                  logo={node.agency_logo}
                  isCareer={careersOnly}
                  target={
                    careersOnly
                      ? node.agency_careers_page
                      : `/our-brands/${node._meta.uid}`
                  }
                  strap={node.agency_one_liner}
                  key={node._meta.uid}
                />
              ))}
            </Content>
          </Col>
        </Row>
      </Grid>
    </Spacer>
  );
};

AgenciesGrid.propTypes = {};

export default AgenciesGrid;

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { StaticQuery, graphql } from "gatsby";
import styled from "styled-components";
import { useRecoilValue } from "recoil";

import { browserState } from "../components/new/ScrollAware";
import Layout from "../components/new/Layout";
import FixedBlock from "../components/new/blocks/FixedBlock";
import HeroBlock from "../components/new/blocks/HeroBlock";
import BrandsBlock from "../components/new/blocks/BrandsBlock";
import AwardsGridBlock from "../components/new/blocks/AwardsGridBlock";
import VideoSingleFull from "../components/new/blocks/VideoSingleFull";

import { extractSocialMetadata } from "../utils/content";
import theme from "../theme/";

const BrandsPage = ({ data }) => {
  const { isIE } = useRecoilValue(browserState);
  const content = data.prismic.ourBrands;
  const agencies = data.prismic.agenciesFirstHalf.edges.concat(
    data.prismic.agenciesSecondHalf.edges
  );
  const capabilities = data.prismic.allCapabilitiess.edges;
  const meta = data.site.siteMetadata;

  if (!content) {
    return null;
  }

  const [hasSocialMetaData, socialMetadata] = extractSocialMetadata(
    content.body1
  );

  return (
    <>
      <Helmet
        htmlAttributes={{
          lang: "en",
        }}
        title={content.meta_title}
        meta={[
          {
            name: `description`,
            content: content.meta_description,
          },
          {
            property: `og:title`,
            content: hasSocialMetaData
              ? socialMetadata.general_card.title
              : meta.title,
          },
          {
            property: `og:description`,
            content: hasSocialMetaData
              ? socialMetadata.general_card.description
              : content.meta_description,
          },
          {
            property: `og:type`,
            content: `website`,
          },
          {
            property: `og:image`,
            content: hasSocialMetaData && socialMetadata.general_card.image,
          },
          {
            name: `twitter:card`,
            content: `summary`,
          },
          {
            name: `twitter:creator`,
            content: meta.author,
          },
          {
            name: `twitter:site`,
            content: hasSocialMetaData && socialMetadata.twitter_handle,
          },
          {
            name: `twitter:title`,
            content: hasSocialMetaData
              ? socialMetadata.twitter_card.title
              : meta.title,
          },
          {
            name: `twitter:description`,
            content: hasSocialMetaData
              ? socialMetadata.twitter_card.description
              : meta.description,
          },
          {
            name: `twitter:image`,
            content: hasSocialMetaData && socialMetadata.twitter_card.image,
          },
        ].concat(meta)}
      />
      <Layout>
        {content.body.map((section, i) => {
          const key = `${section.type}_${i}`;
          const { type, primary: data, fields: awards } = section;

          switch (section.type) {
            case "image_hero":
              return (
                <FixedBlock
                  index={isIE ? theme.zIndex.background : theme.zIndex.swipe}
                  key={key}
                >
                  <HeroBlock
                    image={data.image_hero_background}
                    title={data.image_hero_heading}
                    subtitle={data.image_hero_heading_sub}
                    largeTitle={data.image_hero_heading_large}
                  />
                </FixedBlock>
              );

            case "video_single_full":
              return <VideoSingleFull key={key} url={data.video_url.url} />;

            case "explore_brands_block":
              return (
                <BrandsBlock
                  key={key}
                  title={data.explore_brands_heading}
                  intro={data.explore_brands_intro}
                  agencies={agencies}
                  capabilities={capabilities}
                />
              );

            case "awards_block":
              return (
                <AwardsGridBlock
                  heading={data.heading}
                  text={data.text}
                  ctaText={data.cta_text}
                  ctaUrl={data.cta_link}
                  awards={awards}
                />
              );
          }
        })}
      </Layout>
    </>
  );
};

BrandsPage.propTypes = {
  data: PropTypes.object.isRequired,
};

const query = graphql`
  query {
    prismic {
      ourBrands(lang: "en-gb", uid: "our-brands") {
        meta_description
        meta_title
        body {
          ... on PRISMIC_OurBrandsBodyImage_hero {
            type
            label
            primary {
              image_hero_heading
              image_hero_heading_sub
              image_hero_heading_large
              image_hero_background
            }
          }
          ... on PRISMIC_OurBrandsBodyVideo_single_full {
            type
            label
            primary {
              video_url {
                ... on PRISMIC__FileLink {
                  _linkType
                  url
                }
              }
            }
          }
          ... on PRISMIC_OurBrandsBodyExplore_brands_block {
            type
            label
            primary {
              explore_brands_heading
              explore_brands_intro
            }
          }
          ... on PRISMIC_OurBrandsBodyAwards_block {
            label
            primary {
              cta_link
              cta_text
              heading
            }
            type
            fields {
              award_heading
              award_image
              award_text
            }
          }
        }
        body1 {
          ... on PRISMIC_OurBrandsBody1General_card {
            type
            label
            primary {
              description
              image
              title
            }
          }
          ... on PRISMIC_OurBrandsBody1Twitter_card {
            type
            label
            primary {
              card_type
              description
              image
              title
              twitter_handle
            }
          }
        }
      }
      agenciesFirstHalf: allAgencys(sortBy: agency_name_ASC) {
        pageInfo {
          hasPreviousPage
          hasNextPage
          startCursor
          endCursor
        }
        edges {
          node {
            _meta {
              uid
            }
            agency_name
            agency_one_liner
            agency_logo
            agency_overview
            capability_agency_overview
            agency_competencies
            agency_website
            agency_careers_page
            agency_is_featured
            body {
              ... on PRISMIC_AgencyBodyAgency_images {
                type
                label
                fields {
                  agency_media_asset {
                    _linkType
                    ... on PRISMIC__ImageLink {
                      _linkType
                      url
                    }
                  }
                }
              }
              ... on PRISMIC_AgencyBodyCapability_agency_media {
                type
                label
                fields {
                  capability_media_asset {
                    _linkType
                    ... on PRISMIC__ImageLink {
                      _linkType
                      url
                      name
                    }
                  }
                }
              }
            }
          }
          cursor
        }
      }
      agenciesSecondHalf: allAgencys(
        after: "YXJyYXljb25uZWN0aW9uOjE5"
        sortBy: agency_name_ASC
      ) {
        edges {
          node {
            _meta {
              uid
            }
            agency_name
            agency_one_liner
            agency_logo
            agency_overview
            capability_agency_overview
            agency_competencies
            agency_website
            agency_careers_page
            agency_is_featured
            body {
              ... on PRISMIC_AgencyBodyAgency_images {
                type
                label
                fields {
                  agency_media_asset {
                    _linkType
                    ... on PRISMIC__ImageLink {
                      _linkType
                      url
                      name
                    }
                  }
                }
              }
              ... on PRISMIC_AgencyBodyCapability_agency_media {
                type
                label
                fields {
                  capability_media_asset {
                    _linkType
                    ... on PRISMIC__ImageLink {
                      _linkType
                      url
                      name
                    }
                  }
                }
              }
            }
          }
          cursor
        }
      }
      allCapabilitiess(sortBy: order_ASC) {
        edges {
          node {
            capability_label
            capability_mobile_label
            capability_intro_copy
            capability_logo
            capability_video {
              _linkType
              ... on PRISMIC__FileLink {
                _linkType
                url
              }
            }
            capability_video_fallback_image {
              _linkType
              ... on PRISMIC__ImageLink {
                url
              }
            }
            order
            body {
              ... on PRISMIC_CapabilitiesBodyRelated_brands {
                type
                label
                fields {
                  related_agency {
                    ... on PRISMIC_Agency {
                      _meta {
                        uid
                      }
                      agency_name
                      agency_is_featured
                      agency_logo
                      agency_overview
                      agency_competencies
                      agency_website
                      agency_careers_page
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        title
        description
        author
      }
    }
  }
`;

const BrandsData = () => (
  <StaticQuery
    query={`${query}`}
    render={(data) => <BrandsPage data={data} />}
  />
);

export default BrandsData;

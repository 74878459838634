import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";

import Heading from "./typography/Heading";

const OptionWrapper = styled.div`
  padding: 40px 20px 30px 20px;
  cursor: pointer;
  border-right: 1px solid ${props => props.theme.colors.grey05};
  border-bottom: 1px solid ${props => props.theme.colors.grey05};
  transition: all 100ms ease-in-out;

  ${props =>
    props.isActive &&
    css`
      background-color: ${props.theme.colors.blue};
      color: ${props.theme.colors.white};
    `}

  &:hover {
    box-shadow: 0 8px 10px rgba(0, 0, 0, 0.225);
  }

  @media (min-width: ${props => props.theme.breakpoints.tablet}px) {
    padding: 50px 20px;
  }
`;

const FilterOption = ({ option, index, isActive, onChange }) => {
  const browserWindow = typeof window !== "undefined" ? window : undefined;

  const updateHistory = name => {
    const urlName = name
      .replace("&", "and")
      .split(" ")
      .join("_");

    if (browserWindow) {
      const pathName = `/our-brands?${urlName}`;
      history.pushState({}, null, pathName);
    }
  };

  const selectFilter = (i, name) => {
    if (isActive) {
      onChange(null);
    } else {
      onChange(i);
      updateHistory(name);
    }
  };

  return (
    <OptionWrapper
      isActive={isActive}
      onClick={() => selectFilter(index, option)}
    >
      <Heading size="xs" as="h4" mute={!isActive} slashBefore light={isActive}>
        {option}
      </Heading>
    </OptionWrapper>
  );
};

FilterOption.propTypes = {
  onChange: PropTypes.func.isRequired,
  option: PropTypes.string.isRequired,
  className: PropTypes.string,
};

FilterOption.defaultProps = {};

export default FilterOption;

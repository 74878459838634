import React, { useState, useEffect, createRef, useRef } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { RichText } from "prismic-reactjs";
import { Link } from "gatsby";
import { Grid, Row, Col } from "react-styled-flexboxgrid";
import find from "lodash/find";
import { useRecoilValue } from "recoil";

import FilterBlock from "./FilterBlock";
import Heading from "../typography/Heading";
import Text from "../typography/Text";
import Spacer from "../Spacer";
import AgencyDetail from "../AgencyDetail";
import FullScreenVideo from "../FullScreenVideo";

import { browserState } from "../ScrollAware";
import play from "../../../images/icons/play.svg";

const Container = styled.section``;

const AgenciesContainer = styled.div`
  background-color: ${(props) => props.theme.colors.grey05};
  padding: 50px 0;
  width: 100%;

  @media (min-width: ${(props) => props.theme.breakpoints.tablet}px) {
    padding: 100px 0;
  }
`;

const Intro = styled.div`
  text-align: center;
  margin-bottom: 24px;

  @media (min-width: ${(props) => props.theme.breakpoints.tablet}px) {
    margin-top: 40px;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.desktop}px) {
    margin-top: 80px;
  }

  p {
    margin-bottom: 16px;
    font-size: 18px;

    @media (min-width: ${(props) => props.theme.breakpoints.tablet}px) {
      font-size: 22px;
      line-height: 1.4;
    }

    @media (min-width: ${(props) => props.theme.breakpoints.desktop}px) {
      font-size: 24px;
    }
  }
`;

const AgenciesList = styled.ul`
  list-style: none;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding-left: 50px;
  margin-bottom: 24px;

  @media (min-width: ${(props) => props.theme.breakpoints.tablet}px) {
    margin-top: 24px;
    margin-bottom: 48px;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.desktop}px) {
    margin-bottom: 72px;
  }
`;

const AgenciesListItem = styled.li`
  padding-right: 50px;

  p {
    white-space: nowrap;
  }

  &:last-child {
    p {
      &::after {
        display: none;
      }
    }
  }
`;

const ScrollLink = styled.a`
  display: inline-block;
  transition: all 100ms ease-in-out;
  border-bottom: 2px solid transparent;

  &:hover {
    border-bottom: 2px solid ${(props) => props.theme.colors.primary};
  }
`;

const Logo = styled.div`
  text-align: center;
  margin: 24px auto;
  max-width: 200px;

  @media (min-width: ${(props) => props.theme.breakpoints.tablet}px) {
    margin: 62px auto 48px auto;
  }
`;

const Video = styled.video`
  width: 100%;
  margin-bottom: 24px;

  @media (min-width: ${(props) => props.theme.breakpoints.tablet}px) {
    margin-bottom: 0;
  }
`;

const VideoContainer = styled.section`
  display: grid;
  position: relative;
  margin: 0;
`;

const PlayButton = styled.button`
  height: 80px;
  width: 80px;
  border-radius: 60px;
  background-color: ${(props) => props.theme.colors.white};
  background-image: url(${play});
  background-size: 100%;
  background-repeat: no-repeat;
  margin: 0 auto;
  display: block;
  cursor: pointer;
  outline: 0;
  border: 0;
  transition: transform 100ms ease-in-out;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  @media (min-width: ${(props) => props.theme.desktop}px) {
    height: 120px;
    width: 120px;
  }
`;

const CapabilitiesFilterBlock = ({
  agencies,
  capabilities,
  currentFilter,
  onUpdate,
}) => {
  const browserWindow = typeof window !== "undefined" ? window : undefined;
  const { isMobile } = useRecoilValue(browserState);
  const [activeFilter, setActiveFilter] = useState(null);
  const [relatedAgencies, setRelatedAgencies] = useState([]);
  const [isHealthFilter, setIsHealthFilter] = useState(false);
  const [showVideo, setShowVideo] = useState(false);
  const [elRefs, setElRefs] = useState([]);
  const introRef = useRef();

  useEffect(() => {
    let relatedBrands =
      activeFilter !== null &&
      capabilities[activeFilter].node.body &&
      capabilities[activeFilter].node.body.length &&
      capabilities[activeFilter].node.body[0].fields;

    if (relatedBrands) {
      const convertToAgency = relatedBrands.map((brand) => {
        const agencyUID = brand.related_agency._meta.uid;
        const agency = find(agencies, ["node._meta.uid", agencyUID]);
        return {
          related_agency: {
            ...brand.related_agency,
            body: agency.node.body,
            capability_agency_overview: agency.node.capability_agency_overview,
          },
        };
      });

      setRelatedAgencies(convertToAgency);

      setIsHealthFilter(
        capabilities[activeFilter].node.capability_label === "Health"
          ? true
          : false
      );
    }
  }, [activeFilter]);

  useEffect(() => {
    currentFilter && setActiveFilter(parseInt(currentFilter));
  }, [currentFilter]);

  useEffect(() => {
    onUpdate();
    setElRefs((elRefs) =>
      Array(relatedAgencies.length)
        .fill()
        .map((_, i) => elRefs[i] || createRef())
    );
  }, [relatedAgencies]);

  const scrollToIntro = () => {
    if (!introRef.current) {
      return;
    }

    const elRect = introRef.current.getBoundingClientRect();

    browserWindow &&
      browserWindow.scrollTo({
        top: elRect.top + document.documentElement.scrollTop - 100,
        behavior: "smooth",
      });
  };

  const scrollToAgency = (i) => {
    if (!elRefs[i].current) {
      return;
    }
    const elRect = elRefs[i].current.getBoundingClientRect();
    browserWindow &&
      browserWindow.scrollTo({
        top: elRect.top + document.documentElement.scrollTop - 80,
        behavior: "smooth",
      });
  };

  useEffect(() => {
    if (activeFilter && capabilities[activeFilter].node.capability_intro_copy) {
      setTimeout(scrollToIntro, 100);
    }
  }, [activeFilter]);

  const startVideo = () => {
    setShowVideo(true);
  };

  const stopVideo = () => {
    setShowVideo(false);
  };

  return (
    <>
      <FilterBlock
        filters={capabilities.map((c) => {
          return {
            label:
              isMobile && c.node.capability_mobile_label
                ? c.node.capability_mobile_label
                : c.node.capability_label,
            order: c.node.order,
          };
        })}
        onChange={setActiveFilter}
        activeFilter={activeFilter}
      />

      {relatedAgencies &&
      relatedAgencies.length &&
      capabilities[activeFilter] ? (
        <>
          <Grid ref={introRef}>
            <Row>
              <Col xs={12} mdOffset={1} md={10}>
                {capabilities[activeFilter].node.capability_logo && (
                  <Logo>
                    <img
                      src={capabilities[activeFilter].node.capability_logo.url}
                      alt="Logo"
                    />
                  </Logo>
                )}
                {capabilities[activeFilter].node.capability_video && (
                  <VideoContainer>
                    <Video
                      preload="auto"
                      loop={false}
                      playsInline
                      poster={
                        capabilities[activeFilter].node
                          .capability_video_fallback_image &&
                        capabilities[activeFilter].node
                          .capability_video_fallback_image.url
                      }
                      startVideo
                    >
                      <source
                        src={
                          capabilities[activeFilter].node.capability_video.url
                        }
                        type="video/mp4"
                      />
                    </Video>
                    <PlayButton onClick={startVideo} />
                  </VideoContainer>
                )}
                {capabilities[activeFilter].node.capability_video && (
                  <FullScreenVideo
                    video={capabilities[activeFilter].node.capability_video}
                    onClick={stopVideo}
                    playing={showVideo}
                    objectFit="contain"
                  />
                )}
              </Col>
            </Row>
            <Row>
              <Col xs={12} lg={8} lgOffset={2}>
                <Intro>
                  {capabilities[activeFilter].node.capability_intro_copy &&
                    RichText.render(
                      capabilities[activeFilter].node.capability_intro_copy
                    )}
                </Intro>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={10} mdOffset={1}>
                <AgenciesList>
                  {relatedAgencies.map((agency, i) => (
                    <AgenciesListItem key={`${agency}-${i}`}>
                      <ScrollLink
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          scrollToAgency(i);
                        }}
                      >
                        <Text bold showSlash>
                          {agency.related_agency &&
                            agency.related_agency.agency_name &&
                            agency.related_agency.agency_name}
                        </Text>
                      </ScrollLink>
                    </AgenciesListItem>
                  ))}
                </AgenciesList>
              </Col>
            </Row>
          </Grid>

          <AgenciesContainer>
            <Grid>
              {relatedAgencies.map((agency, i) => {
                const sliderImageSrc =
                  agency.related_agency.body &&
                  agency.related_agency.body[1] &&
                  agency.related_agency.body[1].fields &&
                  isHealthFilter
                    ? agency.related_agency.body[1].fields
                    : agency.related_agency.body[0].fields;
                const capabilityOverview =
                  isHealthFilter &&
                  agency.related_agency.capability_agency_overview
                    ? agency.related_agency.capability_agency_overview
                    : agency.related_agency.agency_overview;

                return (
                  <Row>
                    <Col xs={12}>
                      <div ref={elRefs[i]}>
                        <AgencyDetail
                          flip={i % 2 !== 0}
                          expanded
                          key={agency.related_agency._meta.uid}
                          name={agency.related_agency.agency_name}
                          logo={agency.related_agency.agency_logo}
                          overview={
                            capabilityOverview ||
                            agency.related_agency.agency_overview
                          }
                          capabilityOverview={capabilityOverview}
                          competencies={
                            agency.related_agency.agency_competencies
                          }
                          homepage={agency.related_agency.agency_website}
                          careersPage={
                            agency.related_agency.agency_careers_page
                          }
                          images={sliderImageSrc || []}
                          margin="0 0 40px"
                        />
                      </div>
                    </Col>
                  </Row>
                );
              })}
            </Grid>
          </AgenciesContainer>
        </>
      ) : (
        <Grid>
          <Row>
            <Col xs={12}>
              <Spacer margin={{ xs: "32px 0 48px", m: "72px 0 80px" }}>
                <Heading size="l" as="h2" grey align="center">
                  Explore our capabilities above.
                </Heading>
              </Spacer>
            </Col>
          </Row>
        </Grid>
      )}
    </>
  );
};

CapabilitiesFilterBlock.propTypes = {
  data: PropTypes.shape({}),
  onUpdate: PropTypes.func,
};

CapabilitiesFilterBlock.defaultProps = {};

export default CapabilitiesFilterBlock;

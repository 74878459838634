import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { RichText } from "prismic-reactjs";
import { Grid, Row, Col } from "react-styled-flexboxgrid";
import { useRecoilValue } from "recoil";

import { browserState } from "../ScrollAware";
import Header from "../typography/Heading";
import AgenciesGrid from "../AgenciesGrid";
import CapabilitiesFilterBlock from "./CapabilitiesFilterBlock";

const Container = styled.section`
  background-color: ${(props) => props.theme.colors.white};
  z-index: ${(props) => props.theme.zIndex.section};
`;

const Content = styled.div`
  z-index: ${(props) => props.theme.zIndex.content};
  position: relative;
  padding-top: 44px;
  background-color: ${(props) => props.theme.colors.white};

  @media (min-width: ${(props) => props.theme.breakpoints.tablet}px) {
    padding-top: 96px;
    background-color: transparent;
  }
`;

const Intro = styled.div`
  p {
    margin-bottom: 16px;
    font-size: 18px;

    @media (min-width: ${(props) => props.theme.breakpoints.tablet}px) {
      font-size: 22px;
      line-height: 1.4;
    }

    @media (min-width: ${(props) => props.theme.breakpoints.desktop}px) {
      font-size: 24px;
    }
  }
`;

const Tabs = styled.div`
  width: 100%;
  height: ${(props) => props.blockHeight}px;
  position: relative;
  transition: 0.5s;
  transition-timing-function: cubic-bezier(0.7, 0.3, 0.1, 1);
  overflow: hidden;
`;

const Tab = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  width: 100%;
  position: absolute;
  opacity: ${(props) => (props.isActive ? 1 : 0)};
  transition: 0.5s;
  z-index: ${(props) => (props.isActive ? 1 : 0)};

  &:first-child {
    left: ${(props) => (props.isActive ? 0 : 10)}%;
  }

  &:last-child {
    right: ${(props) => (props.isActive ? 0 : 10)}%;
  }
`;

const BrandsBlock = ({ title, intro, agencies, capabilities }) => {
  const browserWindow = typeof window !== "undefined" ? window : undefined;
  const search = browserWindow ? browserWindow.location.search : "";
  const { isMobile } = useRecoilValue(browserState);
  const leftRef = useRef();
  const rightRef = useRef();
  const [currentFilter, setCurrentFilter] = useState(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const [blockHeight, setBlockHeight] = useState(0);

  useEffect(() => {
    if (!browserWindow) {
      return;
    }

    const queryString = search.replace("?", "");
    const filters = {};

    capabilities.forEach((c, i) => {
      const label =
        isMobile && c.node.capability_mobile_label
          ? c.node.capability_mobile_label
          : c.node.capability_label;
      filters[`${label.replaceAll("&", "and").split(" ").join("_")}`] = i;
    });

    setCurrentFilter(search !== "" ? filters[queryString] : null);
  }, [browserWindow, search]);

  useEffect(() => {
    if (currentFilter) {
      // move to second tab because we know we have filters active
      setActiveIndex(1);
    }
  }, [currentFilter]);

  const getActiveBlockHeight = () => {
    const ref = activeIndex === 0 ? leftRef : rightRef;
    if (ref.current) {
      setBlockHeight(ref.current.clientHeight);
    }
  };

  useEffect(getActiveBlockHeight, [activeIndex, currentFilter]);

  return (
    <Container>
      <Content>
        {title && intro && (
          <Grid>
            <Row>
              <Col xs={12} mdOffset={1} md={10}>
                <Header margin="0 0 xl" size="l" as="h2">
                  {title}
                </Header>
                <Intro>{RichText.render(intro)}</Intro>
              </Col>
            </Row>
          </Grid>
        )}

        <Tabs blockHeight={blockHeight}>
          <Tab ref={leftRef} isActive={activeIndex === 0}>
            <AgenciesGrid agencies={agencies} />
          </Tab>
          <Tab ref={rightRef} isActive={activeIndex === 1}>
            <CapabilitiesFilterBlock
              agencies={agencies}
              capabilities={capabilities}
              currentFilter={currentFilter}
              onUpdate={getActiveBlockHeight}
            />
          </Tab>
        </Tabs>
      </Content>
    </Container>
  );
};

BrandsBlock.propTypes = {};

export default BrandsBlock;

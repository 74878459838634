import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const VideoContainer = styled.div`
  video {
    width: 100%;
    max-height: 100%;
    object-fit: fill;
  }
`;

const VideoSingleFull = ({ url }) => {
  const fileType = url.split(".").pop();

  return (
    <VideoContainer>
      <video autoPlay muted loop playsInline>
        <source src={url} type={`video/${fileType}`} />
      </video>
    </VideoContainer>
  );
};

export default VideoSingleFull;

VideoSingleFull.propTypes = {
  url: PropTypes.string.isRequired,
};
